import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export default class CasinoSlotsPrefetchOld extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const { homePageType } = useSiteConfigStore();

    let location: RouteLocationRaw = {
      name: CasinoRouteName.CASINO_LOBBY,
      params: to.params,
    };

    if ((homePageType === HomePageType.LIVE_GAMES || homePageType === HomePageType.SLOTS)
      && !to.params.groupUrl
      && !to.params.categoryUrl) {
      location = {
        name: RouteName.HOME,
      };
    }

    next(router.resolve301location(location));

    return Promise.resolve();
  }
}
